@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: left !important;
  background-color: #fafafa;
  margin-top: 20px;
}

body {
  margin: 0px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  padding: 5px;
  background-color: #6772e5;
  height: 100px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.cancelButton {
  color: #0076f0;
  font-size: 15px;
}

.filledButton {
  background-color: #0071f0;
  color: rgb(255, 255, 255);
  /* border-color: rgb(117, 71, 255); */
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 15px 8px 15px;
}

.topButton {
  color: rgb(26, 115, 232);
  border: 0.7px solid rgb(199, 199, 199);
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 15px;
  background-color: rgb(255, 255, 255);
  padding: 8px 17px;
  border-radius: 6px;
  cursor: pointer;
  float: right;
  margin-top: 20px;
}

.hairlineButton {
  color: rgb(26, 115, 232);
  border: 0.7px solid rgb(227, 225, 225);
  font-family: 'Avenir Next';
  font-weight: 500;
  font-size: 12px;
  background-color: rgb(255, 255, 255);
  padding: 8px 15px;
  border-radius: 6px;
  cursor: pointer;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.cardTitle {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  line-height: 36px;
  font-weight: 600;
}

.cardActions {
  width: 50%;
}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
/* .StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
} */

@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/Avenir-Next/AvenirNext-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: heavy;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/Avenir-Next/AvenirNext-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: medium;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/Avenir-Next/AvenirNext-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/Sofia\ Pro\ Black.otf') format('opentype');
  font-weight: 600;
  font-style: heavy;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/Sofia\ Pro\ Medium.otf') format('opentype');
  font-weight: 400;
  font-style: medium;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/Sofia\ Pro\ Semi\ Bold.otf') format('opentype');
  font-weight: 500;
  font-style: medium;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/sofia-pro-regular-webfont.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/Sofia\ Pro\ Ultra\ Light.otf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Brown';
  src: url('../fonts/Brown-Regular\ \(1\).otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Brown';
  src: url('../fonts/BrownPro-Light.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Brown';
  src: url('../fonts/BrownPro-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

/**Desktop Query*/
@media only screen and (min-width: 768px) {
  .invoicePage {
    padding-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 100px;
    margin-left: 25%;
    margin-right: 25%;
  }

  .dashPage {
    padding-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 100px;
    margin-left: 25%;
    margin-right: 25%;
  }

  .remainingBalanceBlock {
    margin-top: 1em;
    max-width: 100%;
    margin-right: 20px;
    color: rgb(0, 0, 0);
    margin-bottom: 1em;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    float: right;
    text-align: right;
  }
}

/*Tablet Query*/
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .invoicePage {
    padding-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 100px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .dashPage {
    padding-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 100px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .remainingBalanceBlock {
    margin-top: 1em;
    max-width: 100%;
    margin-right: 20px;
    color: rgb(0, 0, 0);
    margin-bottom: 1em;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    float: right;
    text-align: right;
  }
}

/*Mobile Query*/
@media only screen and (max-width: 480px) {
  .invoicePage {
    padding-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 100px;
    margin-left: 4%;
    margin-right: 4%;
  }

  .dashPage {
    padding-bottom: 20px;
    margin-top: 0;
    margin-bottom: 100px;
    margin-left: 4%;
    margin-right: 4%;
  }

  .remainingBalanceBlock {
    max-width: 100%;
    margin-left: 20px;
    color: rgb(0, 0, 0);
    margin-bottom: 1em;
    font-weight: 500;
    /* display: inline-block; */
    vertical-align: middle;
    /* float: right; */
    /* text-align: right; */
  }
}

.sectionTitle {
  color: rgb(90, 118, 133);
  margin-bottom: 28px;
  width: 100%;
  font-family: Brown;
  font-size: 23px;
  font-weight: 300;
}

.bs-Fieldset-row {
  box-sizing: content-box;
  border-bottom: 1px solid #edf1f3;
  position: relative;
  padding: 0 0 0 20px;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 48px;
}

.bs-Fieldset-row .bs-Fieldset-label {
  position: relative;
  min-width: 42%;
  width: 42%;
}

.bs-Fieldset-fields {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 50px;
  flex-basis: 200px;
}

.hs-Form-row,
.hs-SelectMenuInput-option {
  min-width: 146px;
}

.bs-TextInput {
  border: none;
  color: #617079;
  font-family: Camphor, Segoe UI, Open Sans, sans-serif;
  font-weight: 500;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  display: block;
  padding: 12px 0;
  width: 100%;
  height: 48px;
  font-size: 15px;
  outline: none;
  tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.hs-ContentGroup-content {
  top: 0;
  left: 0;
  right: 0;
}

.hs-ContentGroup {
  position: relative;
  overflow: hidden;
}