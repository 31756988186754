// Dashboard Styles
.section-header {
  font-size: 20px;
  color: #4e4e4e;
  font-family: Brown;
  font-weight: 500;
  text-align: left;
  margin-bottom: 20px;
}

.antd-pro-avatar-list-avatarList ul {
  padding: 0px;
}

.archive-button {
  cursor: pointer;
  font-size: 17px;
  margin-left: 5px;
  color: #1890ff;
  font-family: Brown;
  font-weight: 500;
  text-align: left;
  margin-bottom: 20px;
}

.ant-modal-close,
.ant-modal-close:hover {
  box-shadow: none !important;
  background-color: #ffffff !important;
  transform: none !important;
}

// Project Card Styles
.header-section {
  display: inline-block;
  vertical-align: middle;
  max-width: '100%';
  flex: 1;
}

.status-section {
  float: right;
  vertical-align: middle;
  flex: 0.5;
  padding-left: 30px;
}

.status-message {
  float: right;
  font-family: Avenir Next;
  font-size: 18px;
  color: #a5a5a5;
  font-weight: 400;
  text-align: right;
  width: 100%;
}

.status-img {
  height: 12px;
  padding-left: 5px;
}

.collaborator-length-header {
  font-family: 'Avenir Next';
  font-size: 14px;
  font-weight: 400;
  color: rgb(125, 125, 125);
  text-decoration: none;
  width: 100%;
}

.milestone-length-header {
  padding-top: 5px;
  color: #696969;
  float: right;
  font-family: Avenir Next;
  font-size: 18px;
  font-weight: 400;
  text-align: right;
  width: 100%;
}

.view-project-button {
  float: right;
  box-shadow: none;
  border: none;
  padding: 0;
  color: #3785ed;
  font-weight: 500;
}

.view-project-button:hover {
  box-shadow: none;
}

.new-project-header {
  color: rgb(0, 0, 0);
  font-size: 23px;
  font-weight: 500;
  font-family: 'Avenir Next';
  margin-top: 10px;
  margin-bottom: 15px;
}

.ant-modal-header {
  padding: 0;
  border-radius: 0;
  border-bottom: none;
}

.running-total {
  margin-left: 10px;
  color: #b3b3b3;
}

.demo-toggle {
  margin-top: 20px;
  display: flex;
  justify-content: cente;
  align-items: center;
}

.demo-toggle-text {
  font-size: 15px;
  font-family: Avenir Next;
  justify-content: middle;
  font-weight: 500;
  margin-right: 10px;
}

.dashboard-top {
  // display: flex;
}

.dash-search {
  width: 22%;
}

.ant-input-search {
  border: 0;
  width: 200px;

  box-shadow: rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.top-button {
  color: rgb(26, 115, 232);
  font-family: 'avenir next';
  font-weight: 500;
  font-size: 15px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  float: right;
  padding: 8px 17px;
  border-radius: 6px;
  border: 0;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

/*Mobile Query*/
@media only screen and (max-width: 480px) {
  .ant-input-search {
    border: 0;
    width: 55%;
    box-shadow: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
}