@import '~react-vis/dist/style';

/**Desktop Query*/
@media only screen and (min-width: 768px) {
  .analyticsPage {
    padding-bottom: 20px;
    margin-top: 50px;
    margin-bottom: 100px;
    margin-left: 20%;
    margin-right: 20%;
  }
}

/*Tablet Query*/
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .analyticsPage {
    padding-bottom: 20px;
    margin-top: 50px;
    margin-bottom: 100px;
    margin-left: 10%;
    margin-right: 10%;
  }
}

/*Mobile Query*/
@media only screen and (max-width: 480px) {
  .analyticsPage {
    padding-bottom: 20px;
    margin-top: 0;
    margin-bottom: 100px;
    margin-left: 4%;
    margin-right: 4%;
  }
}

.milestoneRow {
  border-bottom: 1px solid #e6e6e6;
  background-color: #ffffff;
}

.milestoneTable {
  border-spacing: 0 1em;
  width: 100%;
}

.milestoneColumn {
  padding: 10px;
}

// Header title styling

.headerContainer {
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 16px;
}

.headerTitle {
  align-items: center;
  font-family: "Avenir Next";
  font-weight: 400;
  font-size: 24px;
  margin-left: 10px;
}